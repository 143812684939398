import * as React from "react"
import Thumbnail from "../components/Thumbnail"
import oct from "../images/thumbnails/oct-thumbnail.jpg"
import allwell from "../images/thumbnails/allwell-thumbnail.jpg"
import greenp from "../images/thumbnails/greenp-thumbnail.jpg"

export default function Projects() {
	return (
		<div className="container">
			<Thumbnail
				title="OCT Website"
				description="Redesigning the website to build trust and improve engagement with parents."
				link="/work/oct"
				image={oct}
			/>
			<Thumbnail
				title="AllWell Medical App"
				description="Simplifying doctor visits and appointment scheduling for clinic patients."
				link="/work/allwell"
				image={allwell}
			/>
			<Thumbnail
				title="GreenP Parking App"
				description="Designing a car dashboard interface to help drivers find and pay for parking spaces."
				image={greenp}
			/>
		</div>
	)
}