import * as React from "react"
import { Link } from "gatsby"
import * as thumbnailStyles from "../styles/thumbnails.module.css"

export default function Thumbnail(props) {
	var imageCode;
	var linkCode;
	if (props.link) {
		imageCode = <Link to={props.link}><img src={props.image} className="img-fluid" alt="Project Screenshot"/></Link>
		linkCode = <Link to={props.link} className={`stretched-link ${thumbnailStyles.stretchedLink}`}>Case Study&nbsp;<span className={thumbnailStyles.rightArrow}>→</span></Link>;
	} else {
		imageCode = <img src={props.image} className="img-fluid" alt="Project Screenshot"/>
		linkCode = "⏳Coming Soon";
	}
	return (
		<div className={`row ${thumbnailStyles.project}`}>
			<div className={`col-xs-12 col-sm-10 col-md-10 col-lg-8 offset-lg-0 col-xl-7 offset-xl-1 ${thumbnailStyles.projectImage}`}>
				{imageCode}
			</div>
			<div className={`col-xs-12 col-sm-10 offset-sm-2 col-md-8 offset-md-4 col-lg-6 offset-lg-6 col-xl-5 ${thumbnailStyles.projectBlurbWrapper}`}>
				<div className={`position-relative ${thumbnailStyles.projectBlurb} ${props.link ? thumbnailStyles.active : ''}`}>
					<h2>{props.title}</h2>
					<p>{props.description}</p>
					{linkCode}
				</div>
			</div>
		</div>
	)
}