import React from "react"
import Layout from "../components/Layout"
import Projects from "../components/Projects"
import * as homeStyles from "../styles/home.module.css"

export default function Home() {
	return (
		<Layout>
			<header className={homeStyles.headerWrapper}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h1>Hi, I'm Humaira! 👋</h1>
							<p>I'm a full-stack product designer based in Toronto. I create data-informed digital experiences with a focus on intuitive, delightful, and accessible design.</p>
							<p className={`text-muted ${homeStyles.textMuted}`}>Senior Product Designer at Rogers.<br/>Graduate of University of Toronto's UX program.<br/>Previously at Microsoft, Qatar National Library, and Carnegie Mellon.</p>
						</div>
					</div>
				</div>
			</header>
			<main className={homeStyles.featuredWork}>
				<Projects/>
			</main>
		</Layout>
	)
}
